<template>
  <div>
    <div class="survey-info">저장 후 설문완료를 클릭하셔야 최종 제출이 됩니다.<br/>한번 제출된 설문은 수정할 수 없습니다.</div>
    <q-form ref="editForm">
      <c-table
        title="기본정보"
        class="q-mb-md mobile-table"
        :columns="grid.columns"
        :data="grid.data"
        customDataTr
        gridHeightAuto
        hideHeader
        hideBottom
        :isTitle="true"
        :columnSetting="false"
        :isFullScreen="false"
        :isExcelDown="false"
        :filtering="false"
      >
        <template v-slot:customDataTr="props">
          <q-tr :props="props" v-if="props.rowIndex===0" no-hover>
            <q-td
              class="text-center th-td-style th-style th-style-1"
              :rowspan="1">
              <span>
                이름
              </span>
            </q-td>
            <q-td
              class="text-center th-td-style td-style-1"
              :rowspan="1">
              <span>
                <c-text
                  required
                  class="survey-text"
                  label=""
                  name="userName"
                  v-model="survey.userName">
                </c-text>
              </span>
            </q-td>
            <q-td
              class="text-center th-td-style th-style th-style-1"
              :rowspan="1">
              <span>
                연령
              </span>
            </q-td>
            <q-td
              class="text-center th-td-style td-style-1"
              :rowspan="1">
              <c-text
                required
                class="survey-text"
                type="number"
                label=""
                name="age"
                v-model="survey.age">
              </c-text>
            </q-td>
          </q-tr>
          <q-tr :props="props" v-if="props.rowIndex===1" no-hover>
            <q-td
              class="text-center th-td-style th-style th-style-1"
              :rowspan="1">
              <span>
                성별
              </span>
            </q-td>
            <q-td
              class="text-center th-td-style td-style-1"
              :rowspan="1">
              <c-radio
                required
                codeGroupCd="SEX_CD"
                label=""
                name="sexCd"
                v-model="survey.sexCd">
              </c-radio>
            </q-td>
            <q-td
              class="text-center th-td-style th-style th-style-1"
              :rowspan="1">
              <span>
                결혼여부
              </span>
            </q-td>
            <q-td
              class="text-center th-td-style td-style-1"
              :rowspan="1">
              <c-radio
                required
                codeGroupCd="MARRIAGE_CD"
                label=""
                name="marriageFlag"
                v-model="survey.marriageFlag">
              </c-radio>
            </q-td>
          </q-tr>
          <q-tr :props="props" v-if="props.rowIndex===2" no-hover>
            <q-td
              class="text-center th-td-style th-style th-style-1"
              :rowspan="1">
              <span>
                현직장경력
              </span>
            </q-td>
            <q-td
              class="text-center th-td-style td-style-1"
              :rowspan="1" :colspan="3">
              <c-text
                class="survey-text"
                label=""
                name="career"
                v-model="survey.career">
              </c-text>
            </q-td>
          </q-tr>
          <q-tr :props="props" v-if="props.rowIndex===3" no-hover>
            <q-td
              class="text-center th-td-style th-style th-style-1"
              :rowspan="1">
              <span>
                작업부서
              </span>
            </q-td>
            <q-td
              class="text-center th-td-style td-style-2"
              :rowspan="1" :colspan="3">
              <span class="custom-text-before-parent">
                <!-- {{survey.deptName}} 
                <b>부</b>  -->
                <c-text
                  required
                  beforeText="업체명"
                  label=""
                  name="deptName"
                  v-model="survey.deptName">
                </c-text>
                <c-text
                  beforeText="라인"
                  label=""
                  name="deptLine"
                  v-model="survey.deptLine">
                </c-text>
                <c-text
                  required
                  beforeText="수행작업"
                  label=""
                  name="deptWork"
                  v-model="survey.deptWork">
                </c-text>
                
              </span>
            </q-td>
          </q-tr>
          <q-tr :props="props" v-if="props.rowIndex===4" no-hover>
            <q-td
              class="text-center th-td-style th-style th-style-1"
              :rowspan="1">
              <span>
                현재작업<br/>(구체적으로)
              </span>
            </q-td>
            <q-td
              class="text-center th-td-style td-style-3"
              :rowspan="1" :colspan="3">
              <span class="custom-text-before-parent">
                <c-text
                  beforeText="작업내용"
                  label=""
                  name="curWorkContents"
                  v-model="survey.curWorkContents">
                </c-text>
                <c-text
                  beforeText="작업기간"
                  label=""
                  name="curWorkRange"
                  v-model="survey.curWorkRange">
                </c-text>
              </span>
            </q-td>
          </q-tr>
          <q-tr :props="props" v-if="props.rowIndex===5" no-hover>
            <q-td
              class="text-center th-td-style th-style th-style-1"
              :rowspan="1">
              <span>
                1일 근무시간
              </span>
            </q-td>
            <q-td
              class="text-center th-td-style td-style-3"
              :rowspan="1" :colspan="3">
              <span class="custom-text-before-parent">
                <c-text
                  style="width:100% !important;display: inline-block;float: left;"
                  type="number"
                  beforeText="시간"
                  label=""
                  name="onedayWorkTimeHour"
                  v-model="survey.onedayWorkTimeHour">
                </c-text>
                <c-text
                  style="width:100% !important;display: inline-block;float: left;"
                  type="number"
                  beforeText="근무 중 휴식시간(식사시간 제외) [분]"
                  label=""
                  name="onedayWorkTimeRestMin"
                  v-model="survey.onedayWorkTimeRestMin">
                </c-text>
                <c-text
                  style="width:100% !important;display: inline-block;float: left;"
                  type="number"
                  beforeText="휴식 횟수"
                  label=""
                  name="onedayWorkTimeRestCnt"
                  v-model="survey.onedayWorkTimeRestCnt">
                </c-text>
              </span>
            </q-td>
          </q-tr>
          <q-tr :props="props" v-if="props.rowIndex===6" no-hover>
            <q-td
              class="text-center th-td-style th-style th-style-1"
              :rowspan="1">
              <span>
                현작업 전에<br/>했던 작업
              </span>
            </q-td>
            <q-td
              class="text-center th-td-style td-style-3"
              :rowspan="1" :colspan="3">
              <span class="custom-text-before-parent">
                <c-text
                  beforeText="작업내용"
                  label=""
                  name="pastWorkContents"
                  v-model="survey.pastWorkContents">
                </c-text>
                <c-text
                  beforeText="작업기간"
                  label=""
                  name="pastWorkRange"
                  v-model="survey.pastWorkRange">
                </c-text>
              </span>
            </q-td>
          </q-tr>
        </template>
      </c-table>
      <q-form ref="editForm">
        <c-card v-if="checkboxItems && checkboxItems.length > 0" class="cardClassDetailForm" title="체크리스트">
          <template slot="card-detail">
            <div class="col-12 muscleWorker-chk-div" v-for="(data, idx) in checkboxItems" :key="idx">
              <c-checkbox
                :isArray="false"
                required
                :comboItems="data.inquiries"
                itemText="inquiryName"
                itemValue="heaMuscleWorkerSurveyInquiryId"
                valueText="inquiryName"
                valueKey="heaMuscleWorkerSurveyInquiryId"
                :label="data.checklistName"
                :name="`value${idx}`"
                v-model="data.value"
              />
            </div>
          </template>
        </c-card>
      </q-form>
      <c-card class="cardClassDetailForm muscleWorker-chk-div muscleWorker-chk-div2" :isTitle="false" bgClass="orange">
        <template slot="card-detail">
          <div class="col-12">
            <c-radio
              :comboItems="[
                { code: 'N', codeName: '아니오(수고하셨습니다. 설문을 다 마치셨습니다.)' },
                { code: 'Y', codeName: '예(“예”라고 답하신 분은 아래 표의 통증부위에 체크(∨)하고, 해당 통증부위의 세로줄로 내려가며 해당사항에 체크(∨)해 주십시오)' },
              ]"
              valueText="codeName"
              valueKey="code"
              class="q-ml-sm lastQue"
              label="귀하의 작업과 관련하여 목, 어깨, 팔/팔꿈치, 손/손가락/손목, 허리, 다리/발 중 어느 한 부위에서라도 통증, 쑤심, 찌릿찌릿함, 뻣뻣함, 화끈거림, 무감각 등을 느끼신 적이 있습니까 ?"
              name="lastQue"
              v-model="survey.lastQue"
            />
          </div>
        </template>
      </c-card>
      <c-table
        title=""
        class="q-mt-md mobile-table2"
        :columns="gridLast.neckColumns"
        :data="gridLast.data"
        gridHeightAuto
        hideBottom
        :isTop="false"
        :columnSetting="false"
        :isFullScreen="false"
        :isExcelDown="false"
        :filtering="false"
        @headerCheckboxChange="headerCheckboxChange"
      >
        <template v-slot:customArea="{ props, col }">
          <c-radio
            v-if="props.row[`${col.name}Flag`] === 'Y'"
            :comboItems="props.row.comboItems"
            label=""
            :name="`value${props.rowIndex}${col.name}`"
            v-model="props.row[col.name]"
          />
          <template v-if="props.rowIndex === 5">
            <span class="custom-text-before-parent">
              <c-text
                beforeText="기타 작성"
                label=""
                :name="`etc${props.rowIndex}${col.name}`"
                v-model="props.row[`${col.name}etc`]">
              </c-text>
            </span>
          </template>
        </template>
      </c-table>
      <c-table
        title=""
        class="q-mt-md mobile-table2"
        :columns="gridLast.shoulderColumns"
        :data="gridLast.data"
        gridHeightAuto
        hideBottom
        :isTop="false"
        :columnSetting="false"
        :isFullScreen="false"
        :isExcelDown="false"
        :filtering="false"
        @headerCheckboxChange="headerCheckboxChange"
      >
        <template v-slot:customArea="{ props, col }">
          <c-radio
            v-if="props.row[`${col.name}Flag`] === 'Y'"
            :comboItems="props.row.comboItems"
            label=""
            :name="`value${props.rowIndex}${col.name}`"
            v-model="props.row[col.name]"
          />
          <template v-if="props.rowIndex === 5">
            <span class="custom-text-before-parent">
              <c-text
                beforeText="기타 작성"
                label=""
                :name="`etc${props.rowIndex}${col.name}`"
                v-model="props.row[`${col.name}etc`]">
              </c-text>
            </span>
          </template>
        </template>
      </c-table>
      <c-table
        title=""
        class="q-mt-md mobile-table2"
        :columns="gridLast.armColumns"
        :data="gridLast.data"
        gridHeightAuto
        hideBottom
        :isTop="false"
        :columnSetting="false"
        :isFullScreen="false"
        :isExcelDown="false"
        :filtering="false"
        @headerCheckboxChange="headerCheckboxChange"
      >
        <template v-slot:customArea="{ props, col }">
          <c-radio
            v-if="props.row[`${col.name}Flag`] === 'Y'"
            :comboItems="props.row.comboItems"
            label=""
            :name="`value${props.rowIndex}${col.name}`"
            v-model="props.row[col.name]"
          />
          <template v-if="props.rowIndex === 5">
            <span class="custom-text-before-parent">
              <c-text
                beforeText="기타 작성"
                label=""
                :name="`etc${props.rowIndex}${col.name}`"
                v-model="props.row[`${col.name}etc`]">
              </c-text>
            </span>
          </template>
        </template>
      </c-table>
      <c-table
        title=""
        class="q-mt-md mobile-table2"
        :columns="gridLast.handColumns"
        :data="gridLast.data"
        gridHeightAuto
        hideBottom
        :isTop="false"
        :columnSetting="false"
        :isFullScreen="false"
        :isExcelDown="false"
        :filtering="false"
        @headerCheckboxChange="headerCheckboxChange"
      >
        <template v-slot:customArea="{ props, col }">
          <c-radio
            v-if="props.row[`${col.name}Flag`] === 'Y'"
            :comboItems="props.row.comboItems"
            label=""
            :name="`value${props.rowIndex}${col.name}`"
            v-model="props.row[col.name]"
          />
          <template v-if="props.rowIndex === 5">
            <span class="custom-text-before-parent">
              <c-text
                beforeText="기타 작성"
                label=""
                :name="`etc${props.rowIndex}${col.name}`"
                v-model="props.row[`${col.name}etc`]">
              </c-text>
            </span>
          </template>
        </template>
      </c-table>
      <c-table
        title=""
        class="q-mt-md mobile-table2"
        :columns="gridLast.waistColumns"
        :data="gridLast.data"
        gridHeightAuto
        hideBottom
        :isTop="false"
        :columnSetting="false"
        :isFullScreen="false"
        :isExcelDown="false"
        :filtering="false"
        @headerCheckboxChange="headerCheckboxChange"
      >
        <template v-slot:customArea="{ props, col }">
          <c-radio
            v-if="props.row[`${col.name}Flag`] === 'Y'"
            :comboItems="props.row.comboItems"
            label=""
            :name="`value${props.rowIndex}${col.name}`"
            v-model="props.row[col.name]"
          />
          <template v-if="props.rowIndex === 5">
            <span class="custom-text-before-parent">
              <c-text
                beforeText="기타 작성"
                label=""
                :name="`etc${props.rowIndex}${col.name}`"
                v-model="props.row[`${col.name}etc`]">
              </c-text>
            </span>
          </template>
        </template>
      </c-table>
      <c-table
        title=""
        class="q-mt-md mobile-table2"
        :columns="gridLast.legColumns"
        :data="gridLast.data"
        gridHeightAuto
        hideBottom
        :isTop="false"
        :columnSetting="false"
        :isFullScreen="false"
        :isExcelDown="false"
        :filtering="false"
        @headerCheckboxChange="headerCheckboxChange"
      >
        <template v-slot:customArea="{ props, col }">
          <c-radio
            v-if="props.row[`${col.name}Flag`] === 'Y'"
            :comboItems="props.row.comboItems"
            label=""
            :name="`value${props.rowIndex}${col.name}`"
            v-model="props.row[col.name]"
          />
          <template v-if="props.rowIndex === 5">
            <span class="custom-text-before-parent">
              <c-text
                beforeText="기타 작성"
                label=""
                :name="`etc${props.rowIndex}${col.name}`"
                v-model="props.row[`${col.name}etc`]">
              </c-text>
            </span>
          </template>
        </template>
      </c-table>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <div class="popup-bottom-bar">
        <div class="popup-bottom-bar-btngroup">
          <q-btn-group outline >
            <c-btn 
              :isSubmit="isSave"
              :url="saveUrl"
              :param="survey"
              :mappingType="mappingType"
              label="저장" 
              icon="save"
              @beforeAction="saveSurvey"
              @btnCallback="saveSurveyCallback" />
            <c-btn 
              v-show="popupParam.heaMuscleWorkerSurveyId"
              :isSubmit="isComplete"
              :url="completeUrl"
              :param="survey"
              mappingType="PUT"
              label="설문완료" 
              icon="check"
              @beforeAction="completeSurvey"
              @btnCallback="completeSurveyCallback" />
          </q-btn-group>
        </div>
        <div class="popup-bottom-bar-close">
          <q-btn flat color="gray" icon="close" @click="closePopup" />
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'symptomSurveyDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heaMuscleWorkerSurveyId: '',
        surveies: [],
        readOnly: false,
        heaInvestigationPlanId: '',
      }),
    },
    tabSurvey: {
      type: Object,
      default: () => ({
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      survey: {
        heaMuscleWorkerSurveyId: '',  // 근골격계 단위작업 작업자 설문 일련번호
        heaInvestigationPlanId: '',  // 근골격계 조사계획 일련번호
        heaMuscleSurveyStepCd: '',  // 설문현황
        userId: '',  // 유저 시퀀스
        userName: '',  // 사용자명
        deptName: '',  // 부서명
        age: null,  // 연령
        career: '',
        sexCd: '',  // 성별
        deptLine: '',  // 작업부서 라인
        deptWork: '',  // 작업부서 수행작업
        marriageFlag: '',  // 결혼여부
        curWorkContents: '',  // 현재작업 - 작업내용
        curWorkRange: '',  // 현재작업 - 작업기간
        onedayWorkTimeHour: '',  // 1일 근무시간 - 시간
        onedayWorkTimeRestMin: '',  // 1일 근무시간 - 휴식 - 분
        onedayWorkTimeRestCnt: '',  // 1일 근무시간 - 휴식 - 횟수
        pastWorkContents: '',  // 현작업 전에 했던 작업 - 작업내용
        pastWorkRange: '',  // 현작업 전에 했던 작업 - 작업기간
        checklsit1: '',  // 체크리스트 1
        checklsit2: '',  // 체크리스트 2
        checklsit3: '',  // 체크리스트 3
        checklsit4: '',  // 체크리스트 4
        checklsit5: '',  // 체크리스트 5
        lastQue: '', // 통증부위 설문여부
        neck: '',  // 목
        neck2: '',  // 목 - 2
        neck3: '',  // 목 - 3
        neck4: '',  // 목 - 4
        neck5: '',  // 목 - 5
        neck6: '',  // 목 - 6
        neck6etc: '',  // 목 - 6기타
        shoulder: '',  // 어깨
        shoulder1: '',  // 어깨 - 1
        shoulder2: '',  // 어깨 - 2
        shoulder3: '',  // 어깨 - 3
        shoulder4: '',  // 어깨 - 4
        shoulder5: '',  // 어깨 - 5
        shoulder6: '',  // 어깨 - 6
        shoulder6etc: '',  // 어깨 - 6기타
        arm: '',  // 팔/팔꿈치
        arm1: '',  // 팔/팔꿈치 - 1
        arm2: '',  // 팔/팔꿈치 - 2
        arm3: '',  // 팔/팔꿈치 - 3
        arm4: '',  // 팔/팔꿈치 - 4
        arm5: '',  // 팔/팔꿈치 - 5
        arm6: '',  // 팔/팔꿈치 - 6
        arm6etc: '',  // 팔/팔꿈치 - 6기타
        hand: '',  // 손/손목/손가락
        hand1: '',  // 손/손목/손가락 - 1
        hand2: '',  // 손/손목/손가락 - 2
        hand3: '',  // 손/손목/손가락 - 3
        hand4: '',  // 손/손목/손가락 - 4
        hand5: '',  // 손/손목/손가락 - 5
        hand6: '',  // 손/손목/손가락 - 6
        hand6etc: '',  // 손/손목/손가락 - 6기타
        waist: '',  // 허리
        waist2: '',  // 허리 - 2
        waist3: '',  // 허리 - 3
        waist4: '',  // 허리 - 4
        waist5: '',  // 허리 - 5
        waist6: '',  // 허리 - 6
        waist6etc: '',  // 허리 - 6기타
        leg: '',  // 다리/발
        leg1: '',  // 다리/발 - 1
        leg2: '',  // 다리/발 - 2
        leg3: '',  // 다리/발 - 3
        leg4: '',  // 다리/발 - 4
        leg5: '',  // 다리/발 - 5
        leg6: '',  // 다리/발 - 6
        leg6etc: '',  // 다리/발 - 6기타
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
      },
      grid: {
        columns: [
          {
            name: 'header1',
            field: 'header1',
            label: 'header1',
            align: 'center',
            sortable: false,
            style: 'width:25%',
          },
          {
            name: 'data1',
            field: 'data1',
            label: 'data1',
            align: 'center',
            sortable: false,
            style: 'width:25%',
          },
          {
            name: 'header2',
            field: 'header2',
            label: 'header2',
            align: 'center',
            sortable: false,
            style: 'width:25%',
          },
          {
            name: 'data2',
            field: 'data2',
            label: 'data2',
            align: 'center',
            sortable: false,
            style: 'width:25%',
          },
        ],
        data: [{}, {}, {}, {}, {}, {}, {}],
      },
      checkboxItems: [],
      gridLast: {
        neckColumns: [],
        shoulderColumns: [],
        armColumns: [],
        handColumns: [],
        waistColumns: [],
        legColumns: [],
        data: [],
      },
      editable: true,
      isSave: false,
      isComplete: false,
      isCancel: false,
      detailUrl: '',
      checklistUrl: '',
      painAreaUrl: '',
      saveUrl: '',
      completeUrl: '',
      cancelUrl: '',
      mappingType: '',
    };
  },
  computed: {
    disabled() {
      return this.survey.heaMuscleSurveyStepCd === 'MS00000005' || this.popupParam.readOnly;
    },
    cancelBtnEditable() {
      return false;
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      this.mappingType = 'POST';
      // url setting
      this.detailUrl = selectConfig.hea.muscleSystem.muscleSurvey.get.url
      this.checklistUrl = selectConfig.hea.muscleSystem.surveyChecklist.list.url
      this.painAreaUrl = selectConfig.hea.muscleSystem.painArea.list.url
      this.saveUrl = transactionConfig.hea.muscleSystem.muscleSurvey.update.url
      this.completeUrl = transactionConfig.hea.muscleSystem.muscleSurvey.complete.url
      this.cancelUrl = transactionConfig.hea.muscleSystem.muscleSurvey.cancel.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      this.getChecklist();
      // 통증부위
      this.setGridLastColumns();
    },
    getChecklist() {
      this.$http.url = this.checklistUrl
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.survey.plantCd,
        useFlag: 'Y',
      }
      this.$http.request((_result) => {
        /**
         * 체크리스트 처리
         * checkbox형태로 for문으로 형태가 그려짐에 따라 v-model선정처리가 필요
         */
        if (_result.data && _result.data.length > 0) {
          let _idx = 1;
          this.$_.forEach(_result.data, item => {
            item.value = this.survey[`checklsit${_idx}`]
            _idx++;
          })
          this.checkboxItems = _result.data
        } else {
          this.checkboxItems = []
        }
      },);
    },
    setGridLastColumns() {
      let _neck = 'N'
      let _shoulder ='N'
      let _arm = 'N'
      let _hand = 'N'
      let _waist = 'N'
      let _leg = 'N'
      if (this.survey) {
        _neck = this.survey.neck === 'Y' ? 'Y' : 'N'
        _shoulder = this.survey.shoulder === 'Y' ? 'Y' : 'N'
        _arm = this.survey.arm === 'Y' ? 'Y' : 'N'
        _hand = this.survey.hand === 'Y' ? 'Y' : 'N'
        _waist = this.survey.waist === 'Y' ? 'Y' : 'N'
        _leg = this.survey.leg === 'Y' ? 'Y' : 'N'
      }
      this.gridLast.neckColumns = [
        {
          name: 'painArea',
          field: 'painArea',
          label: '통증부위',
          align: 'center',
          sortable: false,
          style: 'width:10%',
        },
        {
          name: 'neck',
          field: 'neck',
          label: '목',
          align: 'center',
          sortable: false,
          style: 'width:15%',
          headerType: 'checkbox',
          value: _neck,
          setHeader: true,
          type: 'custom'
        },
      ]
      this.gridLast.shoulderColumns = [
        {
          name: 'painArea',
          field: 'painArea',
          label: '통증부위',
          align: 'center',
          sortable: false,
          style: 'width:10%',
        },
        {
          name: 'shoulder',
          field: 'shoulder',
          label: '어깨',
          align: 'center',
          sortable: false,
          style: 'width:15%',
          headerType: 'checkbox',
          value: _shoulder,
          setHeader: true,
          type: 'custom'
        },
      ]
      this.gridLast.armColumns = [
        {
          name: 'painArea',
          field: 'painArea',
          label: '통증부위',
          align: 'center',
          sortable: false,
          style: 'width:10%',
        },
        {
          name: 'arm',
          field: 'arm',
          label: '팔/팔꿈치',
          align: 'center',
          sortable: false,
          style: 'width:15%',
          headerType: 'checkbox',
          value: _arm,
          setHeader: true,
          type: 'custom'
        },
      ]
      this.gridLast.handColumns = [
        {
          name: 'painArea',
          field: 'painArea',
          label: '통증부위',
          align: 'center',
          sortable: false,
          style: 'width:10%',
        },
        {
          name: 'hand',
          field: 'hand',
          label: '손/손목/손가락',
          align: 'center',
          sortable: false,
          style: 'width:15%',
          headerType: 'checkbox',
          value: _hand,
          setHeader: true,
          type: 'custom'
        },
      ]
      this.gridLast.waistColumns = [
        {
          name: 'painArea',
          field: 'painArea',
          label: '통증부위',
          align: 'center',
          sortable: false,
          style: 'width:10%',
        },
        {
          name: 'waist',
          field: 'waist',
          label: '허리',
          align: 'center',
          sortable: false,
          style: 'width:15%',
          headerType: 'checkbox',
          value: _waist,
          setHeader: true,
          type: 'custom'
        },
      ]
      this.gridLast.legColumns = [
        {
          name: 'painArea',
          field: 'painArea',
          label: '통증부위',
          align: 'center',
          sortable: false,
          style: 'width:10%',
        },
        {
          name: 'leg',
          field: 'leg',
          label: '다리/발',
          align: 'center',
          sortable: false,
          style: 'width:15%',
          headerType: 'checkbox',
          value: _leg,
          setHeader: true,
          type: 'custom'
        },
      ]
      this.$http.url = this.painAreaUrl
      this.$http.type = 'GET';
      this.$http.param = {
        useFlag: 'Y',
      }
      this.$http.request((_result) => {
        if (_result.data && _result.data.length > 0) {
          let _idx = 1;
          this.$_.forEach(_result.data, item => {
            item.neck = this.survey[`neck${_idx}`]
            item.shoulder = this.survey[`shoulder${_idx}`]
            item.arm = this.survey[`arm${_idx}`]
            item.hand = this.survey[`hand${_idx}`]
            item.waist = this.survey[`waist${_idx}`]
            item.leg = this.survey[`leg${_idx}`]

            if (_idx === 6) {
              this.$set(item, `necketc`, this.survey[`neck${_idx}etc`])
              this.$set(item, `shoulderetc`, this.survey[`shoulder${_idx}etc`])
              this.$set(item, `armetc`, this.survey[`arm${_idx}etc`])
              this.$set(item, `handetc`, this.survey[`hand${_idx}etc`])
              this.$set(item, `waistetc`, this.survey[`waist${_idx}etc`])
              this.$set(item, `legetc`, this.survey[`leg${_idx}etc`])
            }
            _idx++;
          })
          this.gridLast.data = _result.data
        } else {
          this.gridLast.data = _result.data
        }
      },);
    },
    headerCheckboxChange(props, col, val) {
      this.$set(this.survey, col.name, val)
    },
    setSaveValue() {
      return new Promise(resolve => {
        this.survey.regUserId = this.$store.getters.user.userId
        this.survey.chgUserId = this.$store.getters.user.userId

        // 체크리스트
        if (this.checkboxItems && this.checkboxItems.length > 0) {
          let _idx = 1;
          this.$_.forEach(this.checkboxItems, item => {
            this.$set(this.survey, `checklsit${_idx}`, item.value)
            _idx++;
          })
        } else {
          this.$set(this.survey, `checklsit1`, '')
          this.$set(this.survey, `checklsit2`, '')
          this.$set(this.survey, `checklsit3`, '')
          this.$set(this.survey, `checklsit4`, '')
          this.$set(this.survey, `checklsit5`, '')
        }

        // 통증부위
        if (this.gridLast.data && this.gridLast.data.length > 0) {
          let _idx = 1;
          this.$_.forEach(this.gridLast.data, item => {
            this.$set(this.survey, `neck${_idx}`, item.neck)
            this.$set(this.survey, `shoulder${_idx}`, item.shoulder)
            this.$set(this.survey, `arm${_idx}`, item.arm)
            this.$set(this.survey, `hand${_idx}`, item.hand)
            this.$set(this.survey, `waist${_idx}`, item.waist)
            this.$set(this.survey, `leg${_idx}`, item.leg)

            if (_idx === 6) {
              this.$set(this.survey, `neck${_idx}etc`, item.necketc)
              this.$set(this.survey, `shoulder${_idx}etc`, item.shoulderetc)
              this.$set(this.survey, `arm${_idx}etc`, item.armetc)
              this.$set(this.survey, `hand${_idx}etc`, item.handetc)
              this.$set(this.survey, `waist${_idx}etc`, item.waistetc)
              this.$set(this.survey, `leg${_idx}etc`, item.legetc)
            }
            _idx++;
          })
        }
        resolve(true);
      });
    },
    saveValue() {
      return new Promise(resolve => {
        this.isSave = !this.isSave
        resolve(true);
      });
    },
    completeValue() {
      return new Promise(resolve => {
        this.isComplete = !this.isComplete
        resolve(true);
      });
    },
    cancelValue() {
      return new Promise(resolve => {
        this.isCancel = !this.isCancel
        resolve(true);
      });
    },
    saveSurvey() {
      if (this.popupParam.heaMuscleWorkerSurveyId) {
        this.mappingType = 'PUT';
        this.saveUrl = transactionConfig.hea.muscleSystem.muscleSurvey.update.url;
        this.survey.heaMuscleWorkerSurveyId = this.popupParam.heaMuscleWorkerSurveyId;
      } else {
        this.mappingType = 'POST';
        this.saveUrl = transactionConfig.hea.muscleSystem.muscleSurvey.update.url + 'Outer';
        this.survey.heaInvestigationPlanId = this.popupParam.heaInvestigationPlanId;
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까? \r\n저장 후 설문완료를 클릭해야 최종완료됩니다.', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              let promises = [
                {
                  func: this.setSaveValue,
                },
                {
                  func: this.saveValue
                },
              ];
              this.$comm.orderedPromise(promises);
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveSurveyCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'heaMuscleWorkerSurveyId', result.data)
      this.survey.heaMuscleWorkerSurveyId = result.data;
      this.getDetail();
    },
    completeSurvey() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          this.survey.heaMuscleWorkerSurveyId = this.popupParam.heaMuscleWorkerSurveyId;
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '설문완료 하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              let promises = [
                {
                  func: this.setSaveValue,
                },
                {
                  func: this.completeValue
                },
              ];
              this.$comm.orderedPromise(promises);
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeSurveyCallback() {
      window.getApp.$emit('ALERT', {
        title: '안내', // 안내
        message: '설문이 완료되었습니다.', // 하나 이상 선택하세요.
        type: 'success', // success / info / warning / error
      });
      this.closePopup();
    },
    cancelSurvey() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '다시작성 하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              let promises = [
                {
                  func: this.setSaveValue,
                },
                {
                  func: this.cancelValue
                },
              ];
              this.$comm.orderedPromise(promises);
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    cancelSurveyCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    closePopup(data) {
      this.$store.dispatch('LogOut').then(() => {
        window.localStorage.removeItem('defaultPlantCd');
        this.$emit('closePopup', data);  
      })  
    },
  }
};
</script>
<style lang="sass">
.th-td-style
  vertical-align: middle !important

.th-style
  background: #EEEEEE !important
  font-weight: 700

.th-style-1
  width: 10%

.td-style-1
  width: 15%

.td-style-2
  width: 65%

.td-style-3
  width: 90%

.custom-text-before-parent
  .custom-text-before
    font-size: 12px !important
    height: 22px !important
    min-height: 22px !important
    line-height: 22px
  .q-field--dense .q-field__inner
    padding-bottom: 0 !important
    padding-top: 3px !important
  .customText.q-field--dense .q-field__control, .customText.q-field--dense .q-field__marginal
    height: 22px !important
    min-height: 22px !important

.lastQue
  label
    padding-bottom: 0px !important

.survey-text
  .q-field--dense 
    .q-field__control 
      min-height: 30px !important
    .q-field__marginal
      min-height: 30px !important

.muscleWorker-chk-div
  .q-field__label.no-pointer-events.absolute.ellipsis
    font-size: 1.25em
    white-space: normal !important
    padding-top: 30px
    line-height: 1.3em
  .q-pb-xs.CoptionGroup.q-option-group.q-gutter-x-sm.q-option-group--inline
    margin-top: 50px
.survey-info
  padding: 10px
  color: red 
  font-size: 1.1em
.mobile-table2 .customRadio .q-field__inner
  min-height: 80px !important
.mobile-table2 .customRadio .q-field__inner
  min-height: 80px !important
.mobile-table2 .customRadio .q-field__control
  min-height: 80px !important
.mobile-table2 .customRadio .q-field__control-container
  min-height: 80px !important
.mobile-table2 .customRadio.q-field--dense .q-field__control
  min-height: 80px !important

.mobile-table .customRadio .q-field__inner
  min-height: 50px !important
.mobile-table .customRadio .q-field__control
  min-height: 50px !important
.mobile-table .customRadio .q-field__control-container
  min-height: 50px !important
.mobile-table .customRadio.q-field--dense .q-field__control
  min-height: 50px !important
.muscleWorker-chk-div2
  min-height: 200px
.muscleWorker-chk-div2 .customCardbody
  min-height: 200px !important
.muscleWorker-chk-div2 .q-field__label.no-pointer-events.absolute.ellipsis
  padding-top: 50px !important
</style>